import React from 'react';
import { useInView } from 'react-intersection-observer';
import { isBrowser } from '../../helpers';

const AnimateIn = ({
  threshold = 0.15,
  distance = 100,
  transition = 400,
  triggerOnce = true,
  showAboveView = true,
  slideUp = false,
  children,
  ...remainingProps
}) => {
  const [ref, inView, entry] = useInView({ threshold, triggerOnce });

  const isAboveView =
    showAboveView &&
    isBrowser() && // Server Side Rendering
    window.pageYOffset > entry?.target.getBoundingClientRect().top + document.documentElement.scrollTop;

  const translate = slideUp ? 'translateY' : 'translateX';

  return (
    <div
      ref={ref}
      style={{
        transition: `opacity ${transition}ms, transform ${transition}ms`,
        opacity: inView || isAboveView ? 1 : 0,
        transform: `${translate}(${inView || isAboveView ? 0 : distance}px)`,
      }}
      {...remainingProps}
    >
      {children}
    </div>
  );
};

export default AnimateIn;
